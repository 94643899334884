.svgMap-map-controls-wrapper {
  display: none !important;
}
.card {
  z-index: 7;
  position: absolute;
  background: #191A35;
  border-radius: 10px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  max-width: 400px;
  max-height: 240px;
  padding-left: 29px;
  padding-right: 38px;
  padding-top: 6px !important;
  min-height: 288px;
}

.article-container {
  min-width: 220px;
  position: relative;
  min-height: 68px;
  max-height: 68px;
  margin-bottom: 13px;
}
.article-wrapper {
  max-height: 165px;
  padding-left: 8px;
  max-width: 165px;
}
.pointer-card {
  height: 40px;
  width: 40px;
  margin: auto;
  transform: rotate(
45deg);
  margin-bottom: -11px;
  margin-top: -38px;
  background: #191A35;
}
.header {
  display: flex;
  max-width: 528px;
  justify-content: space-between;
  margin-right: -10px;
}
.article-image {
  max-width: 143px !important;
  margin-bottom: 10px;
  width: auto !important;
  /* height: 117px !important; */
  min-height: 143px;
  max-height: 143px;
  min-width: 143px !important;
  object-fit: cover;
}
.content {
  padding-bottom: -13px;
  background: #fff;
  margin-bottom: 20px !important;
  margin-right: -10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 10px;
  max-width: 528px;
  max-height: 165px;
}

.overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 210px;
}

.userPrice {
  color: #09289a;
  font-weight: 700;
  text-align: right;
  font-size: 18px;
  margin-bottom: 0;
  margin-top: 0;
}

.india-color {
  color: #09289a;
  font-weight: 700;
  text-align: right;
  position: absolute;
  display: none;
  margin-bottom: 0;
  margin-top: 0;
  bottom: 0;
  margin-right: 10px;
  right: 0;
}

.india-time {
  color: #000000;
  line-height: 1;
  font-size: 15px;
  font-weight: 700;
  margin: 0;
}
.title-wrap {
  margin-left: 9px;
}

.artical-title {
  font-size: 18px;
  max-height: 21px;
  margin: 0 auto;
  margin-left: 10px;
  color: #000;
  font-style: italic;
  font-weight: 500;
}

.artical-detils {
  padding-left: 10px;
  font-size: 16px;
  display: -webkit-box;
  margin-top: 10px;
  color: #C1272D;
  min-height: 55px;
  max-width: 305px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.atrtical-url {
  margin: 10px;
  font-style: italic;
  font-size: 15px;
  margin-top: 5px;
}

.hr-artical {
  margin-left: 10px;
  width: calc(100% - 20px);
  height: 1px;
  background-color: #09289a;
  min-width: 209px;
  border: none;
}

.atrtical-url a{
  color: #09289a;
  text-decoration: none;
}

.player-image {
  width: 105px !important;
  height: auto;
  border-radius: 5px;
  padding-left: 7px;
}
.svgMap-country {
  stroke: #237AFF !important;
  fill: #A8CBFF;
}
.svgMap-map-wrapper {
  background-color: #fff;
  height: 100vh;
  padding-top: 0%;
}
.svgMap-map-image {
  z-index: 2;
  background-color: transparent !important;
}
.green-big-round-shap {
  position: absolute;
  display: none;
  height: 220px;
  width: 220px;
  background: #d4f9bb;
  top: 0px;
  border-radius: 50%;
  z-index: 1;
  left: 135px;
}

.red-small-round-shap {
  position: absolute;
  height: 80px;
  width: 80px;
  background: #ef7d7c;
  border-radius: 50%;
  z-index: 1;
  display: none;
  left: 87px;
  top: 208px;
}

.green-small-round-shap {
  position: absolute;
  height: 80px;
  width: 80px;
  background: #d4f9bb;
  display: none;
  border-radius: 50%;
  z-index: 1;
  left: 170px;
  top: 319px;
}

.bottom-red-small-round-shap {
  position: absolute;
  height: 55px;
  display: none;
  width: 55px;
  background: #d4f9bb;
  border-radius: 50%;
  z-index: 1;
  right: 210px;
  bottom: 120px;
}

.bottom-green-big-round-shap {
  position: absolute;
  height: 150px;
  width: 150px;
  background: #ef7d7c;
  display: none;
  bottom: 99px;
  border-radius: 50%;
  z-index: 1;
  right: 0px;
}

.sm-header {
  display: none;
}

.header .Country_logo {    
  max-width: unset;
  width: unset;
}

.new-artic-unblock {
    height: 50px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .new-artic-unblock h2 {
    margin: 0 auto;
    margin-left: 17px;
    color: #fff;
    text-align: left;
    font-size: 21px;
  }
.box-sm-cont .new-artic-unblock 
{
    display: none;
}
.country-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.card-country {
  color: #FFFFFF;
  margin-right: 10px;
  font-weight: 500;
}
.cardCountry {
  width: 1.4em !important;
  height: 1.4em !important;
}
.card-box {
  display: flex;
  align-items: center;
}
.desk {
  display: flex;
  padding-right: 8px;
  align-items: center;
}
.pub-logo {
  width: auto !important;
  min-height: 26.2px;
  max-height: 26.2px;
}
.country-name {
  font-size: 12px;
  color: #808080;
  font-weight: 300;
  margin-right: 7px;
}
.header {
    padding-left: 10px;
    border-top-left-radius: 10px;
    background: #fff;
    padding-bottom: 11.5px;
    padding-top: 11.5px;
    align-items: center;
    border-top-right-radius: 10px;
    padding-right: 18px;
}

.logo_name {
  display: flex;
  align-items: center;
}

.Country_logo {
  width: 100%;
  max-width: 55px;
  margin-right: 8px;
}

.svg-active {
  fill: #237AFF;
}



.publisher_logo_url {
  width: 100%;
  text-align: center;
}

.publisher_logo_url img {
  max-width: 150px;
}

@media only screen and (max-width: 991px) {
  .rootCard {
    display: flex;
    justify-content: center;
  }
  .svgMap-map-wrapper {
    height: 81vh;
  }
  .pub-logo {
    min-height: 31.2px;
    max-height: 31.2px;
  }
  .card {
    max-width: 465px;
    max-height: 238px;
    top: 53%;
    min-height: 296px;
  }
  .new-artic-unblock h2 {
    padding-top: 0;
    font-size: 19px;
  }
  .country-name {
    font-size: 11px;
  }
  .article-wrapper {
    max-width: 287px;
  }
  .article-image {
    max-width: 154px !important;
    min-height: 154px;
    max-height: 154px;
    min-width: 154px !important;
  }
  .artical-title {
    font-size: 18px;
  }
  .overflow {
    width: 267px;
  }
  .article-container {
    min-width: 217px;
  }
  .article-container {
    min-height: 83px;
    max-height: 83px;
    margin-bottom: 14px;
  }
  .artical-detils {
    font-size: 14px;
    -webkit-line-clamp: 5;
    min-height: 85px;
    max-width: 268px;
  }
}
@media only screen and (max-width: 350px) {
  .card-box {
    margin-right: -6px;
  }
  .new-artic-unblock h2 {
    margin-left: 2px !important;
  }
  .logo_name {
    padding-left: 16px !important;
  }

  .card-box {
    padding-right: 29px !important;
  }
  .header-price {
    padding-right: 14px !important;
    margin-right: -4px;
  }
  .article-wrapper {
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 510px) {
  .rootCard {
    height: auto !important;
  }
  .content {
    flex-direction: column;
    align-items: center;
    min-height: 347px;
  }
  .pub-logo {
    width: auto !important;
    max-height: 31.9px;
    max-width: 92.47px !important;
  }
  .imageContainer {
    width: 100%;
    height: unset !important;
  }
  .article-wrapper {
    padding-left: 0 !important;
    max-width: 87% !important;
    padding-right: 0 !important;
  }
  .overflow {
    overflow: hidden;
  }
  .imageContainer {
    margin-bottom: 5px !important;
  }
  .article-image {
    margin-left: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
  }
  .player-image {
    border-radius: unset;
    max-width: 100% !important;
  }
  .artical-detils {
    max-height: 69px !important;
    min-height: 69px !important;
    -webkit-line-clamp: 4 !important;
  }
  .article-container {
    max-height: 69px !important;
    min-height: 69px;
  }
}
@media only screen and (max-width: 390px) {
  .title-wrap {
    display: none;
  }
  .card-box {
    padding-right: 36px !important;
  }
  .header-price {
    padding-right: 20px !important;
  }

  .card-country {
    margin-right: 8px;
  }
  img.player-image.pub-logo {
    max-width: 70px !important;
    height: auto;
  }
  .userPrice {
    font-size: 17px !important;
  }
  .new-artic-unblock h2 {
    font-size: 1.2rem !important;
  }
  .card-country {
    font-size: 1rem !important;
  }
  .mobile-country {
    width: 1.2em !important;
  }
}
@media only screen and (max-width: 600px) {
  .rootCard {
    height: 39vh;
  }
  .new-artic-unblock h2 {
    margin-left: 11px;
  }
  #root {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .article-image {
    padding-left: 0;
    min-width: 180px !important;
    min-height: 180px;
    margin-left: 25px;
    max-height: 180px;
  }
  .article-wrapper {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    padding-left: 22px;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    padding-right: 25px;
  }
  .content {
    padding-left: 0;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 198px;
  }
  .overflow {
    width: 100%;
    margin-bottom: 6px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
  .imageContainer {
    margin-bottom: 15px;
    height: 100%;
  }
  .hr-artical {
    max-width: 100%;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .article-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 100%;
  }
  .artical-detils {
    padding-left: 0;
    max-width: 100%;
    width: 100%;
    max-height: 100px;
    -webkit-line-clamp: 6;
    min-height: 82px;
    margin-top: 2px;
    margin-bottom: 9px;
  }
  .india-color {
    max-width: 100%;
    margin: 0;
    position: relative;
  }
  .card-box {
    margin-left: 11px;
    padding-right: 40px;
  }
  .pub-logo {
    padding-left: 0;
  }
  .header {
    width: 100%;
    padding-left: 0;
    max-width: 100%;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-right: 0;
  }
  .logo_name {
    padding-left: 25px;
  }
  .new-artic-unblock h2 {
    font-size: 1.5rem;
  }
  .header-price {
    padding-right: 25px;
  }
  .userPrice {
    font-size: 19px;
  }
  .cardCountry {
    width: 1.9em !important;
    height: 1.9em !important;
  }
  .card-country {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1.2rem;
  }
  .desk {
    align-items: center;
    padding-left: 14px;
    padding-right: 14px;
    margin-top: 10px;
    margin-bottom: 7px;
    height: auto;
    width: 100%;
  }
  .rootCard {
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
  }
  .card {
    position: unset;
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    padding-right: 25px;
    align-items: start;
    justify-content: center;
    max-height: 100%;
    height: 100%;
    width: 100%;
    max-width: 100%;
  }
}
/* @media only screen and (max-width: 600px) {
  .box-sm-cont .new-artic-unblock 
{
    display: block;
}
    .desk.new-artic-unblock   {
        display: none;
    }
  .header {
    display: none;
  }
  .sm-header {
    display: block;
    display: flex;
    padding: 10px;
  }
  .box-sm-cont {
    display: block;
  }
  #root {
    height: 100vh;
  }
  .rootCard {
    height: 100vh;
    align-items: center;
  }
  .card {
    position: unset;
    padding: unset;
  }
  svg {
    pointer-events: none !important;
  }
  .svgMap-tooltip .svgMap-tooltip-content-wrapper .svgMap-tooltip-pointer{
    display: none!important;
  }
  .player-image {
    width: 100% !important;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    z-index: 1000;
    margin-top: -20px;
    padding-left: 0px;
  }
  .box-sm-cont .overflow {
    width: calc(100% - 23px);
  }
  .artical-detils {
    width: calc(100% - 23px) !important;
    text-align: left;
    max-width: unset;
  }
  .new-artic-unblock {
    display: block;
    background: #08289a;
    height: 70px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
  .new-artic-unblock h2 {
    margin: 0 auto;
    margin-left: 11px;
    color: #fff;
    text-align: left;
    font-size: 19px;
  }
  .green-big-round-shap {
    display: none;
    left: 45px;
    top: 50px;
    height: 120px;
    width: 120px;
  }
  .red-small-round-shap {
    left: 174px;
    display: none;
    top: 62px;
    height: 15px;
    width: 15px;
  }
  .bottom-green-big-round-shap {
    height: 80px;
    width: 80px;
    display: none;
    bottom: 179px;
    z-index: 20;
  }
  .bottom-red-small-round-shap {
    right: 102px !important;
    display: none;
    bottom: 186px !important;
    height: 30px;
    width: 30px;
  }
  .card {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
}
 */